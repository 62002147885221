import React from 'react';
import './reviewStars.css'

const Stars = ({ count }) => {
  const renderStars = () => {
    const stars = [];

    for (let i = 0; i < count; i++) {
      stars.push(<i className="fa fa-star" key={i}></i>);
    }

    return stars;
  };

  return <div className="stars">{renderStars()}</div>;
};

export default Stars;
