import React, {useEffect} from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";
import { Col, Container, Row } from 'react-bootstrap';
import drmanju from '../../../../../Images/drmanjunath.jpg'
import './about.css';
import { Link } from 'react-router-dom';


export default function AboutDoctor() {
    useEffect(() => {
        Aos.init({
                duration: 2000,
            });
        Aos.refresh();
      }, []);

      const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

  return (
    <section className="about-wrapper">
        <Container>
            <Row>
                <Col md={12} lg={6}>
                    <div>
                        <img src={drmanju} className="img-fluid donto" alt='drmanjunath' style={{height:'620px'}}/>
                    </div>
                </Col>
                <Col md={12} lg={6}>
                    <div className="about-right mt-5 mt-lg-0">
                        <div className="about-content text-start" data-aos="zoom-in">
                           <h1>Best Orthopedic Doctor in Hyderabad</h1>
                           <p>
                           Dr. Manjunath is the <span style={{fontWeight:'bold'}}>best orthopedic surgeon in Nizampet</span>. With years of experience and expertise in the field, he is dedicated to providing top-quality orthopedic care. Whether you are dealing with joint pain, sports injuries, or any orthopedic condition, Dr. Manjunath offers personalized treatment and comprehensive care to help you recover and improve your quality of life.
                           </p>
                           <Link to='/about' onClick={scrollToTop} >About Us</Link>
                        </div>
                        <div className="fun-fact-sec" data-aos="fade-right">
                                <div className="single-fun">
                                    <span>5000</span>
                                    <span>+</span>
                                    <p>Happy Patients</p>
                                </div>
                                <div className="single-fun sp-fun" data-aos="fade-right">
                                    <span>3</span>
                                    <span>+</span>
                                    <p>Qualified Doctors</p>
                                </div>
                                <div className="single-fun" data-aos="fade-left">
                                    <span>25</span>
                                    <span>+</span>
                                    <p>Years Experience</p>
                                </div>
                                <div className="single-fun sp-fun" data-aos="fade-left">
                                    <span>50</span>
                                    <span>+</span>
                                    <p>Ortho Awards</p>
                                </div>
                                <span className="line"></span>
                        </div>
      
                    </div>
                   
                </Col>
            </Row>
        </Container>
    </section>
  )
}
