import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './practice.css'

export default function OurOrthoPractice() {
  return (
   <section className="about-content-sec">
    <Container>
        <Row>
            <Col md={8} lg={12 }   className="text-left">
            <div className="section-title  text-center">
                <h1>Our Orthro Practice</h1>
            </div>
            
                <ul className="w-50 m-auto content-inner" style={{color:'black'}}>
                    <li>Orthopedic practice focuses on diagnosing, treating, and preventing conditions affecting the musculoskeletal system, including bones, joints, ligaments, tendons, and muscles.</li>
                    <li>Orthopedic practitioners utilize advanced medical techniques, such as surgical interventions, non-surgical treatments, rehabilitation therapies, and personalized care plans, to address a wide range of orthopedic issues.</li>
                    <li>Orthopedic practice encompasses specialties like joint replacements, sports medicine, trauma surgery, spine disorders, pediatric orthopedics, and orthopedic oncology, providing comprehensive care for patients of all ages.</li>
                    <li>With a commitment to improving mobility, alleviating pain, and enhancing quality of life, orthopedic practice plays a crucial role in restoring and maintaining musculoskeletal health, enabling individuals to lead active and fulfilling lives.</li>
                </ul>
            
            </Col>
        </Row>
    </Container>
   </section>
  )
}
