import React from 'react';
import { FaWhatsapp, FaPhone, FaEnvelope, FaArrowUp, FaLinkedin } from 'react-icons/fa';
import './icons.css';

export default function IconToTop() {
    const handleScrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
    
      return (
        <div>
          {/* Your other content here */}
          {/* WhatsApp Icon */}
         
    
          {/* Bottom-to-Top Icons */}
          <div id="bottomToTopIcons">
          <a href="https://wa.me/919948890963" target="_blank" rel="noopener noreferrer">
  <FaWhatsapp className="icon" style={{ color: 'green' }} />
</a>
            <a href="tel:+919948890963" className="social-icon">
    <FaPhone className="icon" />
  </a>
  <a
      href="https://www.linkedin.com/in/drmanjunath-orthopedic-b69546278/"
      className="social-icon"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaLinkedin  className="icon" style={{ color: '#0e76a8',  }}/>
    </a>
  <a href="mailto:enquiry@drmanjunath.com" className="social-icon" >
    <FaEnvelope className="icon" style={{ color: 'orange' }}/>
  </a>
            {/* Add more icons as needed */}
            <FaArrowUp className="icon" onClick={handleScrollToTop}   style={{color:'red'}}/>
          </div>
        </div>
      );
    }