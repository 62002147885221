import React from 'react'
import { Col, Container, NavLink, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './Footer.css'
import IconsSm from './Icons.Sm';

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className='footer-bg'>
      <Container>
      <Row className='text-white'>
      <Col xs={6} md={3}>
            <div className="single-footer-widget">
              <div className="widget-title">
                <h2>Quick Links</h2>
              </div>
              <div className="widget-content" style={{color:'white'}}>
                <NavLink className="footer-link" ><Link to="/home" style={{color:'white'}} onClick={scrollToTop} >Home </Link></NavLink>
                <NavLink className="footer-link" ><Link to="/about" style={{color:'white'}} onClick={scrollToTop}>About </Link></NavLink>
                <NavLink className="footer-link" ><Link to="/service"  style={{color:'white'}} onClick={scrollToTop}>Services </Link></NavLink>
                <NavLink className="footer-link" ><Link to="/contact" style={{color:'white'}} onClick={scrollToTop}>Contact Us </Link></NavLink>
              </div>
            </div>
          </Col>
          <Col xs={6} md={3}>
              <div className='single-footer-widget'>
                <div>
                  <h2>Our Social Media Handles</h2>
                </div>
                <div>
                   <IconsSm/> 
                </div>
              </div>
          </Col>
          <Col>
              <div className='single-footer-widget'>
                <div className='widget-title'>
                  <h2>Our Services</h2>
                </div>
                <div className='widget-content'>
                  <NavLink className="footer-link"  ><Link to="Robotic-Knee-Replacement-Surgery-In-Hyderabad" style={{ color: 'white' }} onClick={scrollToTop}>Robotic Knee Replacement</Link></NavLink>
                  <NavLink className="footer-link"  ><Link to="Total-Knee-Replacement-Surgery-In-Hyderabad" style={{ color: 'white' }} onClick={scrollToTop}>Total Knee Replacement</Link></NavLink>
                  <NavLink className="footer-link"  ><Link to="Shoulder-Replacement-Specialist-In-Hyderabad" style={{ color: 'white' }} onClick={scrollToTop}>Shoulder Replacement</Link></NavLink>
                  <NavLink className="footer-link"  ><Link to="Hip-Replacement-Doctor-In-Hyderabad" style={{ color: 'white' }} onClick={scrollToTop}>Hip Replacement</Link></NavLink>
                  <NavLink className="footer-link"  ><Link to="Arthroscopic-Shoulder-Surgeon-In-Hyderabad" style={{ color: 'white' }} onClick={scrollToTop}>Shoulder Arthroscopy</Link></NavLink>
                  <NavLink className="footer-link"  ><Link to="Arthroscopic-Knee-Surgery-In-Hyderabad" style={{ color: 'white' }} onClick={scrollToTop}>Knee Arthroscopy</Link></NavLink>
                  <NavLink className="footer-link"  ><Link to="Revision-Surgeries-In-Hyderabad" style={{ color: 'white' }} onClick={scrollToTop}>Revision Surgeries</Link></NavLink>
                  <NavLink className="footer-link"  ><Link to="Complex-Trauma-Care-In-Hyderabad" style={{ color: 'white' }} onClick={scrollToTop}>Complex Trauma</Link></NavLink>
                </div>
              </div>
          </Col>
        <Col xs={6} md={3}>
            <div className='single-footer-widget'>
              <div className='widget-title'>
                <h2>Our Address</h2>
                <p>Dr.Manjunath - Best Orthopedic Surgeon in Nizampet, Ground floor, Nizampet Rd, below Aadhya Skandha, Nizampet Colony, diagnostics, Hyderabad, Telangana 500090</p>
              </div>
              <div className='widget-content'>
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe className="gmap_iframe" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.com/maps?width=310&height=242&hl=en&q=Dr.Manjunath - Best Orthopedic Surgeon in Nizampet, Ground floor, Nizampet Rd, below Aadhya Skandha, Nizampet Colony, diagnostics, Hyderabad, Telangana 500090&t=&z=7&ie=UTF8&iwloc=B&output=embed"  title='maps'/>
                  </div>
                    <style dangerouslySetInnerHTML={{__html: ".mapouter{position:relative;text-align:right;width:310px;height:242px;}.gmap_canvas {overflow:hidden;background:none!important;width:310px;height:242px;}.gmap_iframe {width:310px!important;height:242px!important;}" }} />
                </div>
              </div>

              </div>
        </Col>
      </Row>
      </Container>
      <div className="footer-copy-right text-center text-black">
        <p className='mb-0'>
        &copy; 2023 - 
          <span className="developer">Dr.Manjunath OrthoCare </span>
          | All Rights Reserved
        </p>
      </div>
    </div>
  )
}
