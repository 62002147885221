import React from 'react'
import { Col, Container,Row } from 'react-bootstrap';
import servicesimg from '../../../../Images/services.jpg'
import { Link } from 'react-router-dom';

export default function ServicesBanner(){
    return (
        <section className="banner-all text-white" >
            <Container>
                <Row className="align-items-center">
                    <Col md={6} lg={6} sm={12}>
                        <div className="section-title" style={{paddingTop:'5px'}}>
                            <h1>Service Details</h1>
                        </div>
                        <div className="breadcrumb-nav">
                            <Link to='/' className="text-decoration-none text-white">Home</Link>
                            <span href="/" className="text-decoration-none text-white ml-2">Services</span>
                        </div>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <div className="hero-slide-right text-start">
                            <div className="banner-animate container-fluid rounded">
                                <img src={servicesimg} alt="" className="img-fluid rounded-circle" />
                               
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};


