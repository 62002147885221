import React from 'react'
import OrthocareBlog from './info'
import BlogTitaleList from './BlogTitaleList'
import './style.css'
import { FaSun, FaMoon } from 'react-icons/fa';
import { useState } from 'react';
import { Helmet } from 'react-helmet';


export default function KneeArthroscopy() {

  const [theme, setTheme] = useState('');

    function handleThemeChange(e){
        if(e.target.checked) {
            setTheme('dark-theme');
        } else {
            setTheme('light-theme');
        }
    }
    function getIcon() {
      return theme === 'dark-theme' ? <FaSun /> : <FaMoon />  ;
    }

  return (
    <div className={theme}>
      <Helmet>
        <title>Arthroscopic Knee Surgery In Hyderabad</title>
        <meta name='description' content='Meet Dr. Manjunath, the leading Knee Arthroscopy Surgeon in Nizampet, offering cutting-edge treatments and compassionate care for knee-related issues. Trust in his expertise for optimal recovery and mobility.'></meta>
      </Helmet>
      <OrthocareBlog />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div style={{ width: '80%', maxWidth: '1000px', margin: '20px' }}>

      <div style={{ marginLeft: '30px' }}>
      <div className="form-switch">
                    <input type="checkbox" onChange={handleThemeChange} className="form-check-input"/> {getIcon()} theme
                </div>
      <br></br>
  <h1 style={{textAlign: 'center'}}>Knee Arthroscopy</h1><hr/>
  <p>Knee arthroscopy is a surgical procedure that allows doctors to view the knee joint without making a large incision (cut) through the skin and other soft tissues. Arthroscopy is used to diagnose and treat a wide range of knee problems.</p>
  <p>&nbsp;During knee arthroscopy, your surgeon inserts a small camera, called an arthroscope, into your knee joint. The camera displays pictures on a video monitor, and your surgeon uses these images to guide miniature surgical instruments.</p>
  <p>Because the arthroscope and surgical instruments are thin, your surgeon can use very small incisions, rather than the larger incision needed for open surgery. This results in less pain and joint stiffness for patients, and often shortens the time it takes to recover and return to favorite activities.</p>
  <h2>Anatomy</h2>
  <p>Your knee is the largest joint in your body and one of the most complex. The bones that make up the knee include the lower end of the femur (thighbone), the upper end of the tibia (shinbone), and the patella (kneecap).</p>
  <p>Other important structures that make up the knee joint include:</p>
  <ul>
    <li><strong>Articular cartilage.</strong>&nbsp;The ends of the femur and tibia, and the back of the patella are covered with articular cartilage. This slippery substance helps your knee bones glide smoothly across each other as you bend or straighten your leg.</li>
    <li><strong>Synovium.</strong>&nbsp;The knee joint is surrounded by a thin lining called synovium. This lining releases a fluid that lubricates the cartilage and reduces friction during movement.</li>
    <li><strong>Meniscus.</strong>&nbsp;Two wedge-shaped pieces of meniscal cartilage between the femur and tibia act as shock absorbers. Different from articular cartilage, the meniscus is tough and rubbery to help cushion and stabilize the joint.</li>
    <li><strong>Ligaments.</strong>&nbsp;Bones are connected to other bones by ligaments. The four main ligaments in your knee act like strong ropes to hold the bones together and keep your knee stable.</li>
    <ul>
      <li>The two collateral ligaments are found on either side of your knee.</li>
      <li>The two cruciate ligaments are found inside your knee joint. They cross each other to form an X with the anterior cruciate ligament in front and the posterior cruciate ligament in back.</li>
    </ul>
  </ul>
  <h2>When Knee Arthroscopy is Recommended</h2>
  <p>Your doctor may recommend knee arthroscopy if you have a painful condition that does not respond to nonsurgical treatment. Nonsurgical treatment includes rest, physical therapy, and medications or injections that can reduce inflammation.</p>
  <p>Knee arthroscopy may relieve painful symptoms of many problems that damage the cartilage surfaces and other soft tissues surrounding the joint.</p>
  <p>Common arthroscopic procedures for the knee include:</p>
  <h1>Best Knee Arthroscopy Surgeon In Nizampet</h1>
  <ul>
    <li>Partial meniscectomy (removal of the meniscus), repair of a&nbsp;torn meniscus, or&nbsp;&lt;a "&gt;meniscus transplantation</li>
    <li>Reconstruction of a&nbsp;torn anterior cruciate ligament&nbsp;or&nbsp;posterior cruciate ligament</li>
    <li>Removal of inflamed synovial tissue&nbsp;</li>
    <li>Trimming or reconstruction of damaged articular cartilage</li>
    <li>Removal of loose fragments of bone or cartilage, like those caused by&nbsp;synovial chondromatosis</li>
    <li>Treatment of patella (kneecap) problems</li>
    <li>Treatment of knee sepsis (infection)</li>
  </ul>
  <p>&nbsp;</p>
  <h2>Preparing for Surgery</h2>
  <h3><em>Evaluations and Tests</em></h3>
  <p>Your orthopaedic surgeon may recommend that you see your primary doctor to assess your general health before your surgery. They will identify any problems that may interfere with the procedure. If you have certain health risks, a more extensive evaluation may be necessary before your surgery.</p>
  <p>To help plan your procedure, your orthopaedic surgeon may order preoperative tests. These may include blood tests or an electrocardiogram (EKG).</p>
  <h3><em>Admissions Instructions</em></h3>
  <p>If you are generally healthy, your knee arthroscopy will most likely be performed as an outpatient procedure. This means you will not need to stay overnight at the hospital.</p>
  <p>Be sure to inform your orthopaedic surgeon of any medications or supplements that you take. You may need to stop taking some of these before surgery.</p>
  <p>The hospital or surgery center will contact you ahead of time to provide specific details about your procedure. Make sure to follow the instructions on when to arrive and especially on when to stop eating or drinking prior to your procedure.</p>
  <h3><em>Anesthesia</em></h3>
  <p>Before your surgery, a member of the anesthesia team will talk with you. Knee arthroscopy can be performed under local, regional, or general anesthesia:</p>
  <ul>
    <li>Local anesthesia numbs just your knee</li>
    <li>Regional anesthesia numbs you below the waist</li>
    <li>General anesthesia puts you to sleep</li>
  </ul>
  <p>Your orthopaedic surgeon and your anesthesiologist will talk to you about which method is best for you.</p>
  <p>&nbsp;</p>
  <BlogTitaleList/>
</div>
</div></div>
    </div>
  )
}
