import React from 'react'
import ContactForm from '../HomePage/Mainbar/ContactForm/Form'
import Aboutbanner from './Banner/Aboutbanner'
import Itsme from './DoctorManju/Itsme'
import OurOrthoPractice from './OuorthoPractice/Practice'
import AboutPatient from './Aboutpatient/AboutPatient'
import Footer from '../HomePage/FooterSection/Footer'
import { Helmet } from 'react-helmet'

export default function About() {
  return (
    <div>
      <Helmet>
      <title>Orthopedic Specialist In Hyderabad</title>
        <meta name='description' content='Dr. Manjunath, the top-rated orthopedic doctor in Nizampet, is dedicated to helping patients regain mobility and lead active lives. Trust his expertise and experience for comprehensive orthopedic solutions.'></meta>
      </Helmet>
        <Aboutbanner/>
        <Itsme/>
        <OurOrthoPractice />
       
    </div>
  )
}
