import './App.css';
import Home from './Components/Pages/HomePage/Home/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from './Components/Pages/not Found/notFound';
import About from './Components/Pages/About/About';
import ServicesComp from './Components/Pages/Services/ServicesComp';
import ContactComp from './Components/Pages/Contact/ContactComp';
import Header from './Components/Pages/HeaderSection/Header';
import Footer from './Components/Pages/HomePage/FooterSection/Footer';
import ContactForm from './Components/Pages/HomePage/Mainbar/ContactForm/Form';
import RoboticKneereplacement from './Components/TreatmentsInfo/RoboticKneereplacement';
import TotalHipReplacement from './Components/TreatmentsInfo/TotalHipReplacement';
import TotalKneeReaplacement from './Components/TreatmentsInfo/TotalKneeReaplacement';
import ShoulderArthroscopy from './Components/TreatmentsInfo/ShoulderArthroscopy';
import ShoulderReplacement from './Components/TreatmentsInfo/ShoulderReplacement';
import KneeArthroscopy from './Components/TreatmentsInfo/KneeArthroscopy';
import RevisionSurgeries from './Components/TreatmentsInfo/RevisionSurgeries';
import ComplexTrauma from './Components/TreatmentsInfo/ComplexTrauma';
import InnovativeShoulderRepair from './Components/TreatmentsInfo/InnovativeShoulderRepair';
import IconToTop from './Components/Pages/IconsToTop/IconToTop';
import Media from './Components/Pages/Media/Media';




function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header/>
      <IconToTop />
      <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/home' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/services' element={<ServicesComp/>} />
          <Route path='/media' element={<Media/>} />
          <Route path='/contact' element={<ContactComp/>} />
          <Route path='/Robotic-Knee-Replacement-Surgery-In-Hyderabad' element={<RoboticKneereplacement />}  />
          <Route path='/Total-Knee-Replacement-Surgery-In-Hyderabad' element={<TotalKneeReaplacement />}  />
          <Route path='/Shoulder-Replacement-Specialist-In-Hyderabad' element={<ShoulderReplacement />}  />
          <Route path='/Hip-Replacement-Doctor-In-Hyderabad' element={<TotalHipReplacement />}  />
          <Route path='/Arthroscopic-Shoulder-Surgeon-In-Hyderabad' element={<ShoulderArthroscopy />}  />
          <Route path='/Arthroscopic-Knee-Surgery-In-Hyderabad' element={<KneeArthroscopy />}  />
          <Route path='/Revision-Surgeries-In-Hyderabad' element={<RevisionSurgeries />}  />
          <Route path='/Complex-Trauma-Care-In-Hyderabad' element={<ComplexTrauma />}  />
          <Route path='/Total-Shoulder-Replacement-Surgery-In-Hyderabad' element={<InnovativeShoulderRepair />}  />



        


          <Route path='*' element={<NotFound/>}/>
      </Routes>
      <ContactForm />

      <Footer />

      </BrowserRouter>
    </div>
  );
}

export default App;
