import React from 'react'
import Testimonials from './Testimonials/Testimonials';
import Services from './ServicesAndTreatments/Services';
import AboutDoctor from './OurExpertDoctor/AboutDoctor';
import Gallery from './Gallery/Gallery';
import ContactForm from './ContactForm/Form';
import AboutOrtho from './banner/aboutOrtho/AboutOrtho';
import Feature from './banner/FeaturesAboutClinic/Feature';
import Carousel from './Slider/Slider3';
import Slider from '../Slider/Slider';
import ReactBootstrapSlider from '../Slider/ReactBootstrapSlider';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Main = () => {

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '6vh', // Set the height to 100vh to center the button vertically on the screen
      };
    
      const buttonStyle = {
        backgroundColor: 'rgb(241, 177, 57)',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
      };
    return (
        <>
        <Helmet>
        <title>Orthopedic Surgeon in Hyderabad</title>
        <meta name='description' content='Meet Dr. Manjunath, the leading orthopedic surgeon in Nizampet, offering personalized care and innovative treatments for a wide range of orthopedic conditions. Your health is in expert hands.'></meta>
        </Helmet>
        <div> 
           <ReactBootstrapSlider />
           <div style={buttonContainerStyle}>
           
                       <Button style={buttonStyle}>
                             <Link to="/contact" style={{ color: 'white', fontWeight:' bold' }}>
                                       Book Appointment
                             </Link>
                       </Button>
            </div>   
            <h1 style={{textAlign:'center'}}>Best Orthopedic Clinic In Nizampet</h1>        
           <Feature/>
           <AboutOrtho />
           <AboutDoctor/>
           <Services/>
           <Testimonials/>
           <Gallery />
          
           </div>
        </>
    );
};

export default Main;