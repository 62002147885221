import React from 'react'
import Mediabanner from './Banner/MediaBanner'
import UKFellowShip from './FellowShip/UKFellowShip'

export default function Media() {
  return (
    <div>
        <Mediabanner/>
        <UKFellowShip/>
    </div>
  )
}
