import React from 'react';
import { Link } from 'react-router-dom';

export default function BlogTitaleList() {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
};
    const blogTitleList = [
        'Robotic Knee Replacement',
        'Total Knee Replacement',
        'Shoulder Replacement',
        'Total Hip Replacement',
        'Shoulder Arthroscopy',
        'Knee Arthroscopy',
        'Revision Surgeries',
        'Complex Trauma',
        'Innovative Shoulder Repair'
    
        // Add more blog titles as needed
      ];

  return (
    <div>
       {/* 
        <div style={{ width: '200px', marginLeft: '20px' }}>
          <h3>Services</h3>
          <ul>
            {blogTitleList.map((title, index) => (
              <li key={index}>
                <Link to={`/service/${index + 1}`} onClick={scrollToTop}>{title}</Link>
              </li>
            ))}
          </ul>
        </div>
       */}
    </div>
  )
}
