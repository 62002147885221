import React from 'react'
import BlogTitaleList from './BlogTitaleList'
import OrthocareBlog from './info'
import './style.css'
import { FaSun, FaMoon } from 'react-icons/fa';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

export default function TotalHipReplacement() {
  const [theme, setTheme] = useState('');

    function handleThemeChange(e){
        if(e.target.checked) {
            setTheme('dark-theme');
        } else {
            setTheme('light-theme');
        }
    }
    function getIcon() {
      return theme === 'dark-theme' ? <FaSun /> : <FaMoon />  ;
    }


  return (
    <div className={theme}>
      <Helmet>
        <title>Hip Replacement Doctor In Hyderabad </title>
        <meta name='description' content='Experience the best Hip Replacement surgery in Nizampet under the skilled hands of Dr. Manjunath, a renowned orthopedic surgeon. Regain your mobility and quality of life with our expert care'></meta>
      </Helmet>
      <OrthocareBlog />
       <div>
       <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div style={{ width: '80%', maxWidth: '1000px', margin: '20px' }}>

      <div style={{ marginLeft: '30px' }}>
      <div className="form-switch">
                    <input type="checkbox" onChange={handleThemeChange} className="form-check-input"/> {getIcon()} theme
</div>
 <br></br>
  <h1 style={{textAlign:  'center'}}>Hip Replacement</h1><hr/>
  <p>Whether you have just begun exploring treatment options or have already decided to undergo hip replacement surgery, this information will help you understand the benefits and limitations of total hip replacement. This article describes:</p>
  <ul>
    <li>How a normal hip works</li>
    <li>The causes of hip pain</li>
    <li>What to expect from hip replacement surgery</li>
    <li>What exercises and activities will help restore your mobility and strength, and enable you to return to everyday activities</li>
  </ul>
  <p>If your hip has been damaged by arthritis, a fracture, or other conditions, common activities such as walking or getting in and out of a chair may be painful and difficult. Your hip may be stiff, and it may be hard to put on your shoes and socks. You may even feel uncomfortable while resting.</p>
  <p>If medications, changes in your everyday activities, and the use of walking supports do not adequately help your symptoms, you may consider hip replacement surgery. Hip replacement surgery is a safe and effective procedure that can relieve your pain, increase motion, and help you get back to enjoying normal, everyday activities.</p>
  <p>Hip replacement surgery is one of the most successful operations in all of medicine. Since the early 1960s, improvements in joint replacement surgical techniques and technology have greatly increased the effectiveness of total hip replacement. According to the Agency for Healthcare Research and Quality, more than 450,000 total hip replacements are performed each year in the United States.</p>
  <h2>Anatomy</h2>
  <p>The hip is one of the body's largest joints. It is a ball-and-socket joint. The socket is formed by the acetabulum, which is part of the pelvis bone. The ball is the femoral head, which is the upper end of the femur (thighbone).</p>
  <p>The bone surfaces of the ball and socket are covered with articular cartilage, a smooth tissue that cushions the ends of the bones and enables them to move easily.</p>
  <p>A thin tissue called the synovial membrane surrounds the hip joint. In a healthy hip, this membrane makes a small amount of fluid that lubricates the cartilage and eliminates almost all friction during hip movement.</p>
  <p>Bands of tissue called ligaments (the hip capsule) connect the ball to the socket and provide stability to the joint.</p>
  <h2>Common Causes of Hip Pain</h2>
  <p>The most common cause of chronic hip pain and disability is arthritis. Osteoarthritis, rheumatoid arthritis, and traumatic arthritis are the most common forms of this disease.</p>
  <ul>
    <li><strong>Osteoarthritis.</strong>&nbsp;This is an age-related wear and tear type of arthritis. It usually occurs in people 50 years of age and older and often in individuals with a family history of arthritis. The cartilage cushioning the bones of the hip wears away. The bones then rub against each other, causing hip pain and stiffness. Osteoarthritis may also be caused or accelerated by subtle irregularities in how the hip developed in childhood.</li>
    <li><strong>Rheumatoid arthritis.</strong>&nbsp;This is an autoimmune disease in which the synovial membrane becomes inflamed and thickened. This chronic inflammation can damage the cartilage, leading to pain and stiffness. Rheumatoid arthritis is the most common type of a group of disorders termed inflammatory arthritis.</li>
    <li><strong>Posttraumatic arthritis.</strong>&nbsp;This can follow a serious hip injury or fracture. The cartilage may become damaged and lead to hip pain and stiffness over time.</li>
    <li><strong>Osteonecrosis.</strong>&nbsp;An injury to the hip, such as a dislocation or fracture, may limit the blood supply to the femoral head. This is called osteonecrosis (also sometimes referred to as avascular necrosis). The lack of blood may cause the surface of the bone to collapse, and arthritis will result. Some diseases can also cause osteonecrosis.</li>
    <li><strong>Childhood hip disease.</strong>&nbsp;Some infants and children have hip problems. Even though the problems are successfully treated during childhood, they may still cause arthritis later in life. This happens because the hip may not grow normally, and the joint surfaces are affected.</li>
  </ul>
  <h1>Best Hip Replacement Surgeon In Nizampet</h1>
  <h2>Description</h2>
  <p>In a total hip replacement (also called total hip arthroplasty), the damaged bone and cartilage is removed and replaced with prosthetic components.</p>
  <ul>
    <li>The damaged femoral head is removed and replaced with a metal stem that is placed into the hollow center of the femur. The femoral stem may be either cemented or "press fit" into the bone.</li>
    <li>A metal or ceramic ball is placed on the upper part of the stem. This ball replaces the damaged femoral head that was removed.</li>
    <li>The damaged cartilage surface of the socket (acetabulum) is removed and replaced with a metal socket. Screws or cement are sometimes used to hold the socket in place.</li>
    <li>A plastic, ceramic, or metal spacer is inserted between the new ball and the socket to allow for a smooth gliding surface.</li>
  </ul>
  <p>&nbsp;</p>
  <BlogTitaleList />
</div>
</div>
</div>
</div>
    </div>
  )
}
