import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Form.css'


export default function ContactForm() {
  return (
<section className="appoinment-wrapper">
      <Container>
        <Row>
          <Col sm={6} md={6}>
            <div className="section-title">
              <h1 className="mt-5">Request Appointment</h1>
            </div>
            <div className="appoinment-form">
              <form action="#" className="row">
                <Col md={6} lg={6}>
                  <input type="text" placeholder="Name" />
                </Col>
                <Col md={6} lg={6}>
                  <input type="email" placeholder="Email" />
                </Col>
                <Col md={6} lg={6}>
                  <input type="phone" placeholder="Phone" />
                </Col>
                <Col md={6} lg={6}>
                  <input type="text" placeholder="Subject" />
                </Col>
                <Col md={12} lg={12}>
                  <textarea name="Message" id="" cols="30" rows="10" placeholder="Message"></textarea>
                </Col>
                <Col md={12} lg={12}>
                  <button className="theme-btn btn-fill form-btn mt-5 btn btn-primary">Submit</button>
                </Col>
              </form>
            </div>
          </Col>
          <Col sm={6} md={6}>
            <div className="container">
              <div className="section-title">
                <h1 className="mt-5 ">Schedule Hours</h1>
              </div>
              <div className="appoinment-form">
                <div className="ms-4 mt-4" style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '400px', backgroundImage: 'linear-gradient(45deg,rgb(77,221,9),rgb(210,43,41))', color: 'white' }}>
                  <div className="">
                    <div className='appointmrnt-form'>
                      <div className="working-time item">
                        <i className="fa fa-hourglass-o"></i>
                        <ul className=" ">
                          <li>Monday: <span>6:30pm - 9:30pm</span></li><hr></hr>
                          <li>Tuesday: <span>6:30pm - 9:30pm</span></li><hr></hr>
                          <li>Wednesday: <span>6:30pm - 9:30pm</span></li><hr></hr>
                          <li>Thursday: <span>6:30pm - 9:30pm</span></li><hr></hr>
                          <li>Friday: <span>6:30pm - 9:30pm</span></li><hr></hr>
                          <li>Saturday: <span>6:30pm - 9:30pm</span></li><hr></hr>
                          <li>Sunday: <span>Closed*</span></li><hr></hr>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>  )
}
