import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactForm from '../HomePage/Mainbar/ContactForm/Form';
import ContactBanner from './Banner/Banner';
import ContactIcons from './ContactIcons/ContactIcons';
import Footer from '../HomePage/FooterSection/Footer';

export default function ContactComp() {
  return (
    <div>
        <ContactBanner/>
        <ContactIcons/>
       
    </div>
  )
}
