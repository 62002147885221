export const ClinicFeature1 = [
    {
        "id": "001",
        "title": "Easy Appointment",
        "description": "At our clinic, we make scheduling appointments quick and convenient. With a single phone call, you can easily book an appointment for your orthopedic needs. ",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature2.svg"
      },
      {
        "id": "002",
        "title": "Good Human Care",
        "description": "We believe in providing exceptional care with a human touch. Your well-being and comfort are our top priorities throughout your orthopedic journey.",
        "img": "  https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
      },
      {
        "id": "003",
        "title": "Appointment Timings",
        "description": "Our clinic operates from **6:30 PM to 9:30 PM**, allowing you to easily schedule appointments after regular working hours, You can appointment working hours also. ",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature2.svg"
      },
];
