export const AboutPatientData = [
    {
        "id": "001",
        "title": "Consolation",
        "description": "At our clinic, we prioritize providing compassionate and empathetic care to our patients. We understand that seeking orthopedic treatment can be a challenging time, and we are here to offer guidance, support, and reassurance throughout your journey. ",
        "img": "https://i.ibb.co/GQmKHnh/heart.png"
    },
    {
        "id": "002",
        "title": "Familiarity",
        "description": "We strive to create a warm and welcoming environment where you feel like a valued member of our orthopedic family. Our team is committed to building strong relationships with our patients, and establishing open lines of communication. ",
        "img": "https://i.ibb.co/N2M8n97/love.png"
    },
    {
        "id": "003",
        "title": "Outcome",
        "description": "Our primary focus is on achieving positive treatment outcomes and improving your overall well-being. We combine our expertise, advanced medical techniques, and personalized treatment plans to optimize your recovery and enhance your quality of life.",
        "img": "https://i.ibb.co/t2cczRh/outcome.png"
    }
];
