import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './about.css';
import orthocare from '../../../../../../Images/orthoCare.jpg'


export default function AboutOrtho() {
  return (
    <div className="container-fluid">
        <Container >
            <Row className="align-items-center">
                <Col md={12} sm={10} lg={6}>
                    <div>
                        <h2>Better Life Through</h2>
                        <h1>Better ortho</h1>
                        <p className="mb-xs-5" style={{color: 'black'}}>Orthopedic care holds the promise of a better life by addressing musculoskeletal conditions that affect mobility, comfort, and overall well-being. With advanced diagnostic tools, personalized treatment plans, and a focus on patient-centric care, orthopedic specialists strive to improve lives by restoring functionality, reducing pain, and enhancing quality of life. From joint replacements to sports medicine and rehabilitation, better orthopedic care empowers individuals to regain their independence, pursue their passions, and enjoy an active, pain-free life.</p>
                    </div>
                </Col>
                <Col md={12} sm={12} lg={6}  >
                    <div className="hero-slide-left justify-content-start align-items-center text-center text-lg-start "  style={{marginRight: '2px'}} >
                        <img src={orthocare} alt="orthro image" style={{ paddingRight: '20px', width: '850px' }} />
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}
