import React, {useEffect} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Aos from 'aos';
import { Treatments } from '../../../../../Constants Data/Treatments';
import { Link } from 'react-router-dom';
import './Services.css';



export default function Services() {

    useEffect(() => {
        Aos.init({
                duration: 2000,
            });
        Aos.refresh();
      }, []);

      const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

       
  return (
    <section className="service-wrapper" style={{ backgroundColor: 'orange' }}>
        <Container>
            <Row>
                <Col>
                <div className="section-title text-center">
                   <h1>Treatments</h1>
                </div>
                </Col>
            </Row>
            <Row>
                {Treatments.map(treatment => (
                    <Col md={6} lg={6} xl={4} xs={12} key={treatment.id}>
                    <div className="single-service-box focus" data-aos="flip-left">
                        <div className="service-icon">
                           <img src={treatment.img} alt="" />

                        </div>
                        <h3>{treatment.title}</h3>
                        <p>{treatment.description}</p>
                        <Link to={`/${treatment.id}`} onClick={scrollToTop} >
                          <button style={{backgroundColor: 'orange', height:'35px', paddingTop:'5px'}}>{treatment.link}</button>
                        </Link>
                    </div>
                    </Col>
                ))}
            </Row>
        </Container>
    </section>
  )
}
