import React from 'react'
import BlogTitaleList from './BlogTitaleList'
import OrthocareBlog from './info'
import './style.css'
import { FaSun, FaMoon } from 'react-icons/fa';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

export default function TotalKneeReaplacement() {

  const [theme, setTheme] = useState('');

    function handleThemeChange(e){
        if(e.target.checked) {
            setTheme('dark-theme');
        } else {
            setTheme('light-theme');
        }
    }
    function getIcon() {
      return theme === 'dark-theme' ? <FaSun /> : <FaMoon />  ;
    }

  return (
    <div className={theme}>
      <Helmet>
      <title>Total Knee Replacement Surgery In Hyderabad</title>
        <meta name='description' content='Experience the Best Knee Replacement Surgery in Nizampet with Dr. Manjunath, a renowned orthopedic specialist. Regain mobility and freedom from knee pain with advanced surgical techniques and compassionate care.'></meta>
      </Helmet>
      <OrthocareBlog/>
  <div>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div style={{ width: '80%', maxWidth: '1000px', margin: '20px' }}>

      <div style={{ marginLeft: '30px' }}>
      <div className="form-switch">
                    <input type="checkbox" onChange={handleThemeChange} className="form-check-input"/> {getIcon()} theme
</div>
 <br></br>
    <h1 style={{textAlign:  'center'}}>Total Knee Replacement</h1><hr/>
    <p>If your knee is severely damaged by arthritis or injury, it may be hard for you to perform simple activities, such as walking or climbing stairs. You may even begin to feel pain while you are sitting or lying down.</p>
    <p>If nonsurgical treatments like medications and using walking supports are no longer helpful, you may want to consider total knee replacement surgery. Joint replacement surgery is a safe and effective procedure to relieve pain, correct leg deformity, and help you resume normal activities.</p>
    <p>Knee replacement surgery was first performed in 1968. Since then, improvements in surgical materials and techniques have greatly increased its effectiveness. Total knee replacements are one of the most successful procedures in all of medicine. According to the Agency for Healthcare Research and Quality, in 2017, more than 754,000 knee replacements were performed in the United States.</p>
    <p>Whether you have just begun exploring treatment options or have already decided to have total knee replacement surgery, this article will help you understand more about this valuable procedure.</p>
    <h2>Anatomy</h2>
    <p>The knee is the largest joint in the body and having healthy knees is required to perform most everyday activities.</p>
    <p>The knee is made up of the lower end of the thighbone (femur), the upper end of the shinbone (tibia), and the kneecap (patella). The ends of these three bones are covered with articular cartilage, a smooth substance that protects the bones and enables them to move easily within the joint.</p>
    <p>The menisci are located between the femur and tibia. These C-shaped wedges act as shock absorbers that cushion the joint.</p>
    <p>Large ligaments hold the femur and tibia together and provide stability. The long thigh muscles give the knee strength.</p>
    <p>All remaining surfaces of the knee are covered by a thin lining called the synovial membrane. This membrane releases a fluid that lubricates the cartilage, reducing friction to nearly zero in a healthy knee.</p>
    <p>Normally, all of these components work in harmony. But disease or injury can disrupt this harmony, resulting in pain, muscle weakness, and reduced function.</p>
    <h2>Cause</h2>
    <p>The most common cause of chronic knee pain and disability is arthritis. Although there are many types of arthritis, most knee pain is caused by just three types: osteoarthritis, rheumatoid arthritis, and posttraumatic arthritis.</p>
    <ul>
      <li><strong>Osteoarthritis.</strong>&nbsp;This is an age-related wear and tear type of arthritis. It usually occurs in people 50 years of age and older, but may occur in younger people, too. The cartilage that cushions the bones of the knee softens and wears away. The bones then rub against one another, causing knee pain and stiffness.</li>
    </ul>
    <ul>
      <li><strong>Rheumatoid arthritis.</strong>&nbsp;This is a disease in which the synovial membrane that surrounds the joint becomes inflamed and thickened. This chronic inflammation can damage the cartilage and eventually cause cartilage loss, pain, and stiffness. Rheumatoid arthritis is the most common form of a group of disorders termed "inflammatory arthritis."</li>
      <li><strong>Posttraumatic arthritis.</strong>&nbsp;This can follow a serious knee injury. Fractures of the bones surrounding the knee or tears of the knee ligaments may damage the articular cartilage over time, causing knee pain and limiting knee function.</li>
    </ul>
    <h1>Best Knee Replacement Surgeon In Nizampet</h1>
    <h2>Description</h2>
    <p>A knee replacement (also called knee arthroplasty) might be more accurately termed a knee "resurfacing" because only the surface of the bones are replaced.</p>
    <p>There are four basic steps to a knee replacement procedure:</p>
    <ul>
      <li><strong>Prepare the bone.</strong>&nbsp;The damaged cartilage surfaces at the ends of the femur and tibia are removed along with a small amount of underlying bone.</li>
      <li><strong>Position the metal implants.</strong>&nbsp;The removed cartilage and bone is replaced with metal components that recreate the surface of the joint. These metal parts may be cemented or "press-fit" into the bone.</li>
      <li><strong>Resurface the patella.</strong>&nbsp;The undersurface of the patella (kneecap) is cut and resurfaced with a plastic button. Some surgeons do not resurface the patella, depending upon the case.</li>
      <li><strong>Insert a spacer.</strong>&nbsp;A medical-grade plastic spacer is inserted between the metal components to create a smooth gliding surface.</li>
    </ul>
    <p>&nbsp;</p>
    <BlogTitaleList />
  </div>
</div>
</div>
</div>
</div>

  )
}
