export const Treatments = [
    {
        "id": "Robotic-Knee-Replacement-Surgery-In-Hyderabad",
        "title": "Robotic Knee Replacement",
        "description": "Robotic Knee Replacement is a cutting-edge surgical procedure that utilizes robotic technology to enhance the precision and accuracy of knee replacement surgeries. This advanced approach ensures improved outcomes, faster recovery, and reduced pain for patients.",
        "link": "Read More",
       // "img": require('../Images/100x109 -1-01.png')
    },
    {
        "id": "Total-Knee-Replacement-Surgery-In-Hyderabad",
        "title": "Total Knee Replacement",
        "description": "Total Knee Replacement is a highly effective procedure for patients suffering from severe knee arthritis or damage. It involves replacing the damaged knee joint with an artificial implant, providing pain relief, improved mobility, and a better quality of your Total knee life. If u Have any quaries contact Us.",
        "link": "Read More",
       // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/selant.svg"
    },
    {
        "id": "Shoulder-Replacement-Specialist-In-Hyderabad",
        "title": "Shoulder Replacement",
        "description": "Shoulder Replacement is a surgical procedure performed to alleviate pain and restore function in patients with severe shoulder joint arthritis or irreparable damage. It involves replacing the damaged shoulder joint with a prosthetic implant, resulting in improved mobility and reduced discomfort.",
        "link": "Read More",
       // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/dictionary.svg"
    },
    {
        "id": "Hip-Replacement-Doctor-In-Hyderabad",
        "title": "Hip Replacement",
        "description": "Hip Replacement is a surgical procedure that involves removing the damaged hip joint and replacing it with an artificial implant. It is typically recommended for patients with severe hip arthritis or fractures, aiming to relieve pain, restore mobility, and enhance overall hip function.",
        "link": "Read More",
       // "img": "https://www.freepik.com/free-photo/pain-hip-joint_923791.htm#query=hip%20replacement&position=1&from_view=search&track=ais"
    },
    {
        "id": "Arthroscopic-Shoulder-Surgeon-In-Hyderabad",
        "title": "Shoulder Arthroscopy",
        "description": "Shoulder Arthroscopy is a minimally invasive surgical procedure used to diagnose and treat various shoulder joint conditions. It involves inserting a small camera and surgical instruments through tiny incisions, allowing the orthopedic surgeon to visualize and address issues such as rotator cuff tears, shoulder instability, or labral tears.",
        "link": "Read More",
       // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/oral.svg"
    },
    {
        "id": "Arthroscopic-Knee-Surgery-In-Hyderabad",
        "title": "Knee Arthroscopy",
        "description": "Knee Arthroscopy is a minimally invasive procedure used to diagnose and treat knee joint problems. The surgeon inserts a small camera and specialized instruments through small incisions, enabling visualization and treatment of conditions like meniscus tears, ligament injuries, or cartilage damage.",
        "link": "Read More",
       // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/general.svg"
    },
    {
        "id": "Revision-Surgeries-In-Hyderabad",
        "title": "Revision Surgeries",
        "description": "Revision Surgeries are performed when a previous joint replacement surgery requires correction or replacement. It may be necessary due to implant wear, instability, infection, or other complications. Revision surgeries aim to restore function and alleviate symptoms, providing long-term joint health.",
        "link": "Read More",
       //"img": require('../Images/Shoulder.jpg')
    },
    {
        "id": "Complex-Trauma-Care-In-Hyderabad",
        "title": "Complex Trauma",
        "description": "Complex Trauma refers to severe musculoskeletal injuries caused by accidents, falls, or high-impact trauma. Orthopedic specialists with expertise in managing complex trauma employ advanced surgical techniques , promote healing, and facilitate recovery for patients.",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "Total-Shoulder-Replacement-Surgery-In-Hyderabad",
        "title": "Innovative Shoulder Repair",
        "description": "Shoulder Arthroscopy is a minimally invasive surgical procedure used to diagnose and treat various shoulder joint conditions. It involves inserting a small camera and surgical instruments through tiny incisions, allowing the orthopedic surgeon to visualize and address issues such as rotator cuff tears, shoulder instability, or labral tears.",
        "link": "Read More",
        // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/general.svg"
      }
];


export const Treatment1 = [
    {
      "id": "001",
      "title": "Precision Knee Surgery",
      "description": "Precision Knee Surgery is a cutting-edge surgical procedure that utilizes robotic technology to enhance the precision and accuracy of knee replacement surgeries. This advanced approach ensures improved outcomes, faster recovery, and reduced pain for patients.",
      "link": "Read More",
      "img": "https://icons8.com/icon/Z7AvcYUrkl0n/add-bookmark"
    },
    {
      "id": "002",
      "title": "Advanced Knee Restoration",
      "description": "Advanced Knee Restoration is a highly effective procedure for patients suffering from severe knee arthritis or damage. It involves replacing the damaged knee joint with an artificial implant, providing pain relief, improved mobility, and a better quality of life.",
      "link": "Read More",
      // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/selant.svg"
    },
    {
      "id": "003",
      "title": "Dynamic Shoulder Reconstruction",
      "description": "Dynamic Shoulder Reconstruction is a surgical procedure performed to alleviate pain and restore function in patients with severe shoulder joint arthritis or irreparable damage. It involves replacing the damaged shoulder joint with a prosthetic implant, resulting in improved mobility and reduced discomfort.",
      "link": "Read More",
      // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/dictionary.svg"
    },
    {
      "id": "004",
      "title": "Superior Hip Resurfacing",
      "description": "Superior Hip Resurfacing is a surgical procedure that involves removing the damaged hip joint and replacing it with an artificial implant. It is typically recommended for patients with severe hip arthritis or fractures, aiming to relieve pain, restore mobility, and enhance overall hip function.",
      "link": "Read More",
      // "img": "https://www.freepik.com/free-photo/pain-hip-joint_923791.htm#query=hip%20replacement&position=1&from_view=search&track=ais"
    },
    {
      "id": "005",
      "title": "Innovative Shoulder Repair",
      "description": "Innovative Shoulder Repair is a minimally invasive surgical procedure used to diagnose and treat various shoulder joint conditions. It involves inserting a small camera and surgical instruments through tiny incisions, allowing the orthopedic surgeon to visualize and address issues such as rotator cuff tears, shoulder instability, or labral tears.",
      "link": "Read More",
      // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/oral.svg"
    },
    {
      "id": "006",
      "title": "State-of-the-Art Knee Procedures",
      "description": "State-of-the-Art Knee Procedures is a minimally invasive procedure used to diagnose and treat knee joint problems. The surgeon inserts a small camera and specialized instruments through small incisions, enabling visualization and treatment of conditions like meniscus tears, ligament injuries, or cartilage damage.",
      "link": "Read More",
      // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/general.svg"
    },
    {
      "id": "007",
      "title": "Specialized Joint Revisions",
      "description": "Specialized Joint Revisions are performed when a previous joint replacement surgery requires correction or replacement. It may be necessary due to implant wear, instability, infection, or other complications. Revision surgeries aim to restore function and alleviate symptoms, providing long-term joint health.",
      "link": "Read More",
      // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/general.svg"
    },
    {
      "id": "008",
      "title": "Comprehensive Trauma Care",
      "description": "Comprehensive Trauma Care refers to severe musculoskeletal injuries caused by accidents, falls, or high-impact trauma. Orthopedic specialists with expertise in managing complex trauma employ advanced surgical techniques and multidisciplinary approaches to restore function, promote healing, and facilitate recovery for patients.",
      "link": "Read More",
      // "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/general.svg"
    }
   
  ];
  