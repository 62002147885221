import React from 'react'
import Main from '../Mainbar/Main';
import Footer from '../FooterSection/Footer';

const Home = () => {

    return (
        <>
        <div> 
          <Main/>
          
          

           </div>
        </>
    );
};

export default Home;