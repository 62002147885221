import React from 'react'
import Services from '../HomePage/Mainbar/ServicesAndTreatments/Services'
import ContactForm from '../HomePage/Mainbar/ContactForm/Form'
import ServicesBanner from './Banner/Banner'
import Footer from '../HomePage/FooterSection/Footer'

export default function ServicesComp() {
  return (
    <div>
       <ServicesBanner/>
        <Services/>
       
    </div>
  )
}
