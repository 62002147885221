import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import img1 from '../../../../Images/FellowShip/fellowship(UK).jpeg';
import img2 from '../../../../Images/FellowShip/fellowship(UK)1.jpeg';
import './slider.css';


export default function UKFellowShip() {
  return (
    <div >
       <section className="doctor-wrapper">
      <Container>
        <Row >
          <Col sm={12}>
            <div className="section-title text-center">
                <br></br>
              <h1 className="mb-3 mb-sm-dent" style={{color:'#11a69c'}}>Fellow in Shoulder Replacement Surgeries (UK)</h1>
            </div>
          </Col>
        </Row>
        <Row >
        <div className="row">
  <div className="column">
    <img src={img1} alt="Forest" style={{width: '100%'}} />
  </div>
  <div className="column">
    <img src={img2} alt="Mountains" style={{width: '100%'}} />
  </div>
</div>

        </Row>
        <Row>
          <Col>
            <h1>Recent Fellowship in Liverpool (UK)</h1>
            <p>I am excited to share that I have recently completed a prestigious fellowship in Liverpool, United Kingdom, which has significantly enriched my skills and knowledge in the field. The experience gained during this fellowship has not only broadened my horizons but also provided me with a unique perspective on the latest advancements in our domain. This valuable opportunity has allowed me to collaborate with experts in the field and engage in cutting-edge research, contributing to my continuous growth as a professional in the medical field.</p>
            <h1>Fellow in Shoulder Replacement Surgeries (UK)</h1>
            <p>One of the highlights of my professional journey includes obtaining a fellowship in shoulder replacement surgeries in the United Kingdom. This specialized training has equipped me with advanced techniques and expertise in addressing complex issues related to shoulder joint conditions. The intensive hands-on experience and exposure to the latest methodologies have honed my skills, enabling me to deliver optimal care to patients undergoing shoulder replacement procedures. This accomplishment reflects my commitment to staying at the forefront of medical advancements and providing the highest quality of healthcare services.</p>
          </Col>
        </Row>
      </Container>
      </section>
    </div>
  )
}