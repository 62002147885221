import React , {useState} from 'react'
import OrthocareBlog from './info';
import BlogTitaleList from './BlogTitaleList';
import { FaSun, FaMoon } from 'react-icons/fa';
import { motion } from 'framer-motion';
import './style.css';
import { Helmet
 } from 'react-helmet';
export default function ComplexTrauma() {

  const [theme, setTheme] = useState('');

    function handleThemeChange(e){
        if(e.target.checked) {
            setTheme('dark-theme');
        } else {
            setTheme('light-theme');
        }
    }
    function getIcon() {
      return theme === 'dark-theme' ? <FaSun /> : <FaMoon />  ;
    }
    

 


  return (
    
    <div>
      <Helmet>
        <title>Complex Trauma Care In Hyderabad</title>
         <meta name="description" content="Discover Comprehensive Trauma Treatment in Nizampet under the expertise of Dr. Manjunath, a renowned surgeon with a proven track record of successful outcomes." />
         <link rel="canonical" href="" />
      </Helmet>
     
    <div className={theme}>
    
      <OrthocareBlog />
     
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div style={{ width: '80%', maxWidth: '1000px', margin: '20px' }}>

      <div style={{ marginLeft: '30px' }}>
      <div className="form-switch">
                    <input type="checkbox" onChange={handleThemeChange} className="form-check-input"/> {getIcon()} theme
                </div>
      <br></br> {/* 
      <motion.div
                animate={{ x: [50, 150, 50], opacity: 1, scale: 1 }}
                transition={{
                    duration: 5,
                    delay: 0.3,
                    ease: [0.5, 0.71, 1, 1.5],
                }}
                initial={{ opacity: 0, scale: 0.5 }}
                whileHover={{ scale: 1.2 }}
            >        </motion.div>
      */}

        <h1 style={{ textAlign: 'center' }}>Complex Trauma (Fractures and Dislocations)</h1>
        <hr />
  <p>Trauma to the shoulder is common. Injuries range from a&nbsp;separated shoulder&nbsp;resulting from a fall onto the shoulder to fractures of the&nbsp;shoulder blade (scapula)&nbsp;or&nbsp;collarbone (clavicle)&nbsp;caused by a high-speed motor vehicle collision.&nbsp;</p>
  
  <h2>Description</h2>
  <p>There are many types of shoulder injuries:</p>
  <ul>
    <li>Fractures are broken bones. Fractures commonly involve the&nbsp;clavicle (collarbone), proximal humerus (top of the upper arm bone), and&nbsp;scapula (shoulder blade).</li>
    <li>Dislocations occur when the bones on opposite sides of a joint do not line up. Dislocations can involve any of three different joints.</li>
    <ul>
      <li>A dislocation of the acromioclavicular joint (collarbone joint) is called a "separated shoulder."</li>
      <li>A dislocation of the sternoclavicular joint&nbsp;interrupts the connection between the clavicle and the breastbone (sternum).</li>
      <li>The glenohumeral joint (the ball and socket joint of the shoulder) can be dislocated toward the front (anteriorly) or toward the back (posteriorly).</li>
    </ul>
    <li>Soft-tissue injuries are tears of the ligaments, tendons, muscles, and joint capsules of the shoulder, such as&nbsp;&gt;rotator cuff tears&nbsp;and&nbsp;labral tears.</li>
  </ul>
  <h1>Best Trauma Treatment In Nizampet</h1><br></br>
  <h2>Cause</h2>
  <h3><em>Fractures</em></h3>
  <p>Fractures of the clavicle or the proximal humerus can be caused by a direct blow to the area from a fall, collision, or motor vehicle collision.</p>
  <p>Because the scapula is protected by the chest and surrounding muscles, it is not easily fractured. Therefore, fractures of the scapula are usually caused by high-energy trauma, such as a high-speed motor vehicle collision. Scapula fractures often occur with simultaneous (at the same time) injuries to the chest.</p>
  <h3><em>Shoulder Dislocation</em></h3>
  <ul>
    <li>Anterior (toward the front) dislocations of the shoulder are caused by the arm being forcefully twisted outward (external rotation) when the arm is above the level of the shoulder. These injuries can occur from many different causes, including a fall or a direct blow to the shoulder.</li>
    <li>Posterior (toward the back) dislocations of the shoulder are much less common than anterior dislocations of the shoulder. Posterior dislocations often occur from seizures or electric shocks when the muscles of the front of the shoulder contract and forcefully tighten.</li>
  </ul>
  <h3><em>Shoulder Separation</em></h3>
  <p>Dislocations of the acromioclavicular joint can be caused by a fall onto the shoulder or by lifting heavy objects. The term "shoulder separation" is a bit misleading, as the joint injured in this condition is&nbsp;<em>not</em>&nbsp;the true shoulder joint.</p>
  <h2>Symptoms</h2>
  <p>Symptoms of fractures in the shoulder are related to the specific type of fracture.</p>
  <h3><em>General Symptoms</em></h3>
  <ul>
    <li>Pain</li>
    <li>Swelling and bruising</li>
    <li>Inability to move the shoulder</li>
    <li>A grinding sensation (crepitus) when the shoulder is moved</li>
    <li>Deformity — "It does not look right"</li>
  </ul>
  <h3><em>Specific Symptoms: Clavicle Fracture</em></h3>
  <ul>
    <li>Swelling and bruising about the middle of the collarbone area</li>
    <li>An area that may have a bump, which is actually the prominent (bulging) ends of the fracture under the skin</li>
    <li>Limited shoulder range of motion, although not as much as with fractures of the proximal humerus</li>
  </ul>
  <h3><em>Specific Symptoms: Proximal Humerus Fracture</em></h3>
  <ul>
    <li>A severely swollen shoulder</li>
    <li>Very limited movement of the shoulder</li>
    <li>Severe pain</li>
    <li>Bruising around the top of the arm</li>
  </ul>
  <h3><em>Specific Symptoms: Scapular Fracture</em></h3>
  <ul>
    <li>Pain</li>
    <li>Swelling</li>
    <li>Severe bruising about the shoulder blade</li>
  </ul>
  <h3><em>Specific Symptoms: Shoulder Separation (Acromioclavicular Joint Separation)</em></h3>
  <ul>
    <li>Pain over the top of the shoulder</li>
    <li>A prominence or bump about the top of the shoulder</li>
    <li>The sensation of something sticking up on the shoulder</li>
  </ul>
  <p><strong>(Left)&nbsp;</strong>Fracture patterns in the scapula.&nbsp;<strong>(Right)</strong>&nbsp;An acromioclavicular joint dislocation with extreme elevation of the clavicle.</p>
  <p><strong><em>(Left) From Zuckerman JD, Koval KJ, Cuomo F: Fractures of the scapula, in Heckman JD (ed): Instr Course Lect 42. Rosemont, IL, Amer Acad of Orthop Surg, 1993, pp 271-281. (Right) From Nuber GW, Bowen MK: Acromioclavicular joint injuries and distal clavicle fractures. J Am Acad Orthop Surg 1997;5:11-18.</em></strong></p>
  <h3><em>Specific Symptoms: Shoulder Dislocation (Glenohumeral Joint Dislocation)</em></h3>
  <ul>
    <li>A prominence, or bump, about the front of the shoulder</li>
    <li>Inability to move the arm</li>
    <li>An arm rotated outward</li>
    <li>The sensation of a "dead arm"&nbsp;</li>
  </ul>
  <p>&nbsp;</p>
  <BlogTitaleList/>
</div>

<div style={{ width: '20%', margin: '20px' }}>
        
        {/* <button onClick={handleDarkModeToggle}>{darkMode ? 'Light Mode' : 'Dark Mode'}</button> */}
      </div>
    </div>
    </div>
    </div>
    </div>
  )
}
