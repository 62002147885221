import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick';
import Aos from 'aos';
import axios from 'axios';
import clientimg from '../../../../../Images/testi1.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './testimonials.css';
import "aos/dist/aos.css";
import Stars from './reviewstars';

export default function Testimonials() {

    useEffect(() => {
        Aos.init({
          duration: 2000,
        });
        Aos.refresh();
      }, []);

    const settings = {
        dots: true, // Show the dots for manual control
        infinite: true, // Enable infinite looping
        speed: 500, // Animation speed
        slidesToShow: 1, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at a time
        autoplay: true, // Enable autoplay
        autoplaySpeed: 5000, // Autoplay interval in milliseconds
      };

      const [testimonials, setTestimonials] = useState([]);

      useEffect(()=>{
        axios
        .get('/API/testimonials.json') // Replace '/api/testimonials' with the appropriate API endpoint or JSON file path
        .then(response => {
          setTestimonials(response.data);
        })
        .catch(error => {
          console.error('Error fetching testimonials:', error);
        });
    }, []);

  return (
    <div className="testimonial-wrapper" style={{ backgroundColor: 'wheat' }}>
        <Container>
            <Row>
                <Col sm={12}>
                <div className="section-title text-center">
                    <h1>Testimonials</h1>
                </div>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col md={6} lg={4} sm={12}>
                    <Slider {...settings} data-aos="fade-right">
                        <div className="client-box text-center">
                            <div className="client-img">
                            <img src={clientimg} alt="" />
                            </div>
                            <h3 className="mt-4">What Our Happy Clients Say</h3>
                        </div>
                    </Slider>
                </Col>
                <Col md={6} lg={8} sm={12}>
                    <Slider {...settings} data-aos="zoom-out">
                        {testimonials.slice(1).map(testimonial => (
                            <div className="review-item text-start" key={testimonial.id}>                                
                              <h3>{testimonial.clientName}</h3>
                                <div className="review-content">
                                    <div className="stars">
                                       <span>5.0 </span><br></br>
                                       <Stars count={5}/>
                                    </div>
                                    <div className="review">{testimonial.message}</div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </Col>
            </Row>
        </Container>
    </div>
  )
}
