import React from 'react';
import { motion } from 'framer-motion';

import ServicesBanner from '../Pages/Services/Banner/Banner';



const OrthocareBlog = () => {
  


  return (
    <motion.div>    
      <ServicesBanner />
    
    </motion.div>

  );
};

export default OrthocareBlog;
