import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './media-banner.css'

export default function Mediabanner() {
  return (
    <section className="banner-all text-white" style={{backgroundColor: '#f09f09', paddingTop: '5px'}}>
    <Container>
        <Row className="align-items-center" >
            <Col md={6} lg={6} sm={12} >
                <div className="section-title">
                    <h1>Media</h1>
                </div>
                <div className="breadcrumb-nav">
                    <a href="/" className="text-decoration-none text-white">Home</a>
                    <span href="/" className="text-decoration-none text-white ml-2">Media</span>
                </div>
            </Col>
          
        </Row>
    </Container>
</section>
  )
}
