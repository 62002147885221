import React from 'react';
import './contactIcons.css';
import { Col, Container, Row } from 'react-bootstrap';
import { FaHome, FaEnvelope, FaPhone   }  from 'react-icons/fa'


export default function ContactIcons() {
  return (
    <section className="contactUs-wrapper">
            <Container>
                <Row>
                    <Col md={6} lg={6} xl={4}>
                        <div className="single-contact icon1">
                            <div className="c-icon">
                                <FaHome/>
                            </div>
                            <div className="c-info text-start">
                                <h4>Address</h4>
                                <p>Nizampet Colony</p>
                                <p>Hyderabad</p>
                                <p>Telangana 500090</p> 
                            </div>
                        </div>
                    </Col>
                    <Col md={6} lg={6} xl={4}>
                        <div className="single-contact icon2">
                            <div className="c-icon">
                                <FaEnvelope/>
                            </div>
                            <div className="c-info text-start">
                                <h4>Email</h4>
                                <p>enquiry@drmanjunath.com</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} lg={6} xl={4}>
                        <div className="single-contact icon3">
                            <div className="c-icon">
                                <FaPhone/>
                            </div>
                            <div className="c-info text-start">
                                <h4>Phone</h4>
                                <p>99488 90963</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
  )
}
