import React from 'react'
import BlogTitaleList from './BlogTitaleList'
import OrthocareBlog from './info'
import './style.css'
import { FaSun, FaMoon } from 'react-icons/fa';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

export default function ShoulderReplacement() {
  const [theme, setTheme] = useState('');

    function handleThemeChange(e){
        if(e.target.checked) {
            setTheme('dark-theme');
        } else {
            setTheme('light-theme');
        }
    }
    function getIcon() {
      return theme === 'dark-theme' ? <FaSun /> : <FaMoon />  ;
    }
  return (
    
    <div className={theme}>
      <Helmet>
      <title>Shoulder Replacement Specialist In Hyderabad</title>
        <meta name='description' content='Experience the Best Shoulder Replacement Surgery in Nizampet under the expert care of Dr. Manjunath. Regain mobility and freedom from pain with our advanced surgical techniques.'></meta>
      </Helmet>
    <div>
      <OrthocareBlog />

    </div>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div style={{ width: '80%', maxWidth: '1000px', margin: '20px' }}>

      <div style={{ marginLeft: '30px' }}>
      <div className="form-switch">
                    <input type="checkbox" onChange={handleThemeChange} className="form-check-input"/> {getIcon()} theme
</div>
 <br></br>
    <h1 style={{textAlign:  'center'}}>Shoulder Replacement</h1><hr />
    <p>Although shoulder joint replacement is less common than knee or hip replacement, it is just as successful in relieving joint pain.</p>
    <p>Shoulder replacement surgery was first performed in the United States in the 1950s to treat severe shoulder fractures. Over the years, shoulder joint replacement has come to be used for many other painful conditions of the shoulder, such as different forms of&nbsp;arthritis.</p>
    <p>Today, about 53,000 people in the U.S. have shoulder replacement surgery each year, according to the Agency for Healthcare Research and Quality. This compares to more than 900,000 Americans a year who have knee and hip replacement surgery.</p>
    <p>If nonsurgical treatments like medications and activity changes are no longer helpful for relieving pain, you may want to consider shoulder joint replacement surgery. Joint replacement surgery is a safe and effective procedure to relieve pain and help you resume everyday activities.</p>
    <p>Whether you have just begun exploring treatment options or have already decided to have shoulder joint replacement surgery, this article will help you understand more about this valuable procedure.</p>
    <h2>Anatomy</h2>
    <p>Your shoulder is made up of three bones: your upper arm bone (humerus), your shoulder blade (scapula), and your collarbone (clavicle). The shoulder is a ball-and-socket joint: The ball, or head, of your upper arm bone fits into a shallow socket in your shoulder blade. This socket is called the glenoid.</p>
    <p>The surfaces of the bones where they touch are covered with articular cartilage, a smooth substance that protects the bones and enables them to move easily. A thin, smooth tissue called synovial membrane covers all remaining surfaces inside the shoulder joint. In a healthy shoulder, this membrane makes a small amount of fluid that lubricates the cartilage and eliminates almost any friction in your shoulder.</p>
    <p>The muscles and tendons that surround the shoulder provide stability and support.</p>
    <p>All of these structures allow the shoulder to rotate through a greater range of motion than any other joint in the body.</p>
    <h2>Description</h2>
    <p>In shoulder replacement surgery, the damaged parts of the shoulder are removed and replaced with artificial components, called a prosthesis.</p>
    <p>The treatment options are either replacement of just the head of the humerus bone (ball), or replacement of both the ball and the socket (glenoid).</p>
    <h2>Cause</h2>
    <p>Several conditions can cause shoulder pain and disability, and lead patients to consider shoulder joint replacement surgery.</p>
    <h3><em>Osteoarthritis (Degenerative Joint Disease)</em></h3>
    <p>Osteoarthritis&nbsp;is an age-related wear-and-tear type of arthritis. It usually occurs in people 50 years of age and older, but may occur in younger people, too. The cartilage that cushions the bones of the shoulder softens and wears away. The bones then rub against one another. Over time, the shoulder joint slowly becomes stiff and painful.</p>
    <p>Unfortunately, there is no way to prevent the development of osteoarthritis. It is a common reason people have shoulder replacement surgery.</p>
    <h1>Rheumatoid Arthritis</h1>
    <p>This is a disease in which the synovial membrane that surrounds the joint becomes inflamed and thickened. This chronic inflammation can damage the cartilage and eventually cause cartilage loss, pain, and stiffness.&nbsp;Rheumatoid arthritis&nbsp;is the most common form of a group of disorders termed inflammatory arthritis.</p>
    <p>Related Articles</p>
    <h6><strong>&nbsp;</strong></h6>
    <p>&nbsp;</p>
    <h1>Post-traumatic Arthritis</h1>
    <p>This can follow a serious shoulder injury. Fractures of the bones that make up the shoulder or tears of the shoulder tendons or ligaments may damage the articular cartilage over time. This causes shoulder pain and limits shoulder function.</p>
    <h1>Rotator Cuff Tear Arthropathy</h1>
    <p>A patient with a very large, long-standing&nbsp;rotator cuff tear&nbsp;may develop cuff tear arthropathy. In this condition, the changes in the shoulder joint due to the rotator cuff tear may lead to arthritis and destruction of the joint cartilage.</p>
    <h1>Avascular Necrosis (Osteonecrosis)</h1>
    <p>Avascular necrosis, or osteonecrosis, is a painful condition that occurs when the blood supply to the bone is disrupted. Because bone cells die without a blood supply, osteonecrosis can ultimately cause the destruction of the shoulder joint and lead to arthritis. Chronic steroid use, deep sea diving, severe fracture of the shoulder, sickle cell disease, and heavy alcohol use are all risk factors for avascular necrosis.</p>
    <h1>Severe Fractures</h1>
    <p>A severe&nbsp;fracture of the shoulder&nbsp;is another common reason people have shoulder replacements. When the head of the upper arm bone is shattered, it may be very difficult for a doctor to put the pieces of bone back in place. In addition, the blood supply to the bone pieces can be interrupted. In this case, a surgeon may recommend a shoulder replacement. Older patients with&nbsp;osteoporosis&nbsp;are most at risk for severe shoulder fractures.</p>
    <h1>Failed Previous Shoulder Replacement Surgery</h1>
    <p>Although uncommon, some shoulder replacements fail, most often because of implant loosening, wear, infection, and dislocation. When this occurs, a second joint replacement surgery — called revision surgery — may be necessary.</p>
    <p>&nbsp;</p>
    <BlogTitaleList />
  </div>
  
</div>
</div>
</div>

  )
}
