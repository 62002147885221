import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import drmanjuround from '../../../../Images/drmanjunath.jpg'

export default function Itsme() {
  return (
    <div>
        <section className="doctor-wrapper">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="section-title text-center">
              <h1 className="mb-3 mb-sm-dent" style={{color:'#12a79d'}}>It's Me</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} className="d-flex justify-content-center">
          <img src={drmanjuround} alt="ORTHRO" className="img-fluid donto"  style={{height:'320px'}}/>
          </Col>
          <Col sm={6} md={8}>
            <div className="doctor-info">
              <h3 style={{color:'purple'}}>Dr.Somesh Manjunath.R.V</h3>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <td><strong>Specialization:</strong></td>
                    <td>Orthopedics</td>
                  </tr>
                  <tr>
                    <td><strong>Experience:</strong></td>
                    <td>20 years</td>
                  </tr>
                  <tr>
                    <td><strong>Qualification:</strong></td>
                    <td>MBBS, MS, DNB(Orthro)</td>
                  </tr>
                  <tr>
                    <td><strong>Achievements:</strong></td>
                    <td>
                      <ul>
                        <li>Fellow in Shoulder Replacement Surgeries (UK)</li>
                        <li>Best Orthopedic Surgeon of the Year</li>
                        <li>Excellence in Orthopedic Surgery Award</li>
                        <li>Outstanding Contribution to Joint Replacement</li>
                        <li>Distinguished Service in Sports Medicine</li>
                        <li>Top Orthopedic Specialist in the Region</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
        
    </div>
  )
}
