import './feature.css';
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './feature.css';
import { ClinicFeature1 } from '../../../../../../Constants Data/clinicFeatures';

function Features(props) {
    const { title, description, img } = props.feature;
    useEffect(() => {
        AOS.init({
                duration: 2000,
            });
        AOS.refresh();
      }, []);

    return (
        <Col md={6} xl={4} lg={6} sm={12}>
            <div className="single-feature-box" data-aos="fade-right">
                <div className="icon-box">
                    <img src={img} alt="" />
                </div>
                <h3>{title}</h3>
                <p>{description}</p>
               
            </div>
        </Col>
  )
}



export default function Feature() {
  return (
    <section className="feature-wrapper">
        <Container>
            <Row className="g-3">
                {
                     ClinicFeature1.map(feature => (
                        <Features key={feature.id} feature={feature}></Features>
                     ))
                }
            </Row>
        </Container>
    </section>
  )
}
