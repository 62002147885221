import React, {useState} from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faPhone,  faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../Images/100x109 -1-03.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube, faLinkedin, } from '@fortawesome/free-brands-svg-icons';

import './Header.css'



export default function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  return (
    <div className="head-bg container-fluid ">
        <div>
          <Navbar className="navbar" collapseOnSelect expand="lg">
            <Container className="container-head" >
            <Navbar.Brand href="/home"><img src={logo} alt="logo" style={{height:"60px"}}/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" expand="lg"/>
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto align-items-center">
                          
                          <Link to="/home" className="list-item text-decoration-none">Home</Link>
                          <Link to="/about" className="list-item text-decoration-none">About</Link>

                          <NavDropdown 
                          title="Services"
                          id="services-dropdown"
                          className="list-item list-item text-decoration-none"
                          style={{color: 'black'}}
                          onMouseEnter={() => setIsDropdownOpen(true)}
                          onMouseLeave={() => setIsDropdownOpen(false)}
                          show={isDropdownOpen}
                          >
                              <NavDropdown.Item as={Link} to="/services" style={{fontWeight: 'bold', color: 'Orange'}}>Services list</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/Robotic-Knee-Replacement-Surgery-In-Hyderabad">Robotic Knee Replacement</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/Total-Knee-Replacement-Surgery-In-Hyderabad">Total Knee Replacement</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/Shoulder-Replacement-Specialist-In-Hyderabad">Shoulder Replacement</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/Hip-Replacement-Doctor-In-Hyderabad">Hip Replacement</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/Arthroscopic-Shoulder-Surgeon-In-Hyderabad">Shoulder Arthroscopy</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/Arthroscopic-Knee-Surgery-In-Hyderabad">Knee Arthroscopy</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/Revision-Surgeries-In-Hyderabad">Revision Surgeries</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/Complex-Trauma-Care-In-Hyderabad">Complex Trauma</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/Total-Shoulder-Replacement-Surgery-In-Hyderabad">Innovative Shoulder Repair</NavDropdown.Item>
                          </NavDropdown>

                          <Link to="/media" className="list-item text-decoration-none">Media</Link>
                          <Link to="/contact" className="list-item text-decoration-none">Contact Us</Link>

                         
                          
                           
                          <div className="social-container">
  <a
    href="tel:+919948890963"
    className="instagram social"
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={faPhone} size="2x" style={{ marginLeft: "-10px" }} />
  </a>
  <a
    href="mailto:enquiry@drmanjunath.com"
    className="youtube social"
    style={{ marginLeft: "-10px" }}
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={faEnvelope} size="2x" style={{ color: "orange" }} />
  </a>

  <a
    href="https://www.facebook.com/profile.php?id=100092630426304"
    className="facebook social"
    style={{ marginLeft: "-10px" }}
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={faFacebook} size="2x" />
  </a>
  <a
    href="https://twitter.com/Drmanjunat75182"
    className="twitter social"
    style={{ marginLeft: "-10px" }}
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={faTwitter} size="2x" />
  </a>
  <a
    href="https://www.instagram.com/drmanjunathortho/"
    className="instagram social"
    style={{ marginLeft: "-10px" }}
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={faInstagram} size="2x" />
  </a>

  <a
    href="https://www.youtube.com/@drmanjunathOrthopedicSurgeon"
    className="youtube social"
    style={{ marginLeft: "-10px", marginRight: "-20px" }}
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={faYoutube} size="2x" />
  </a>
</div>
                           
                        </Nav>
                      </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
    </div>
  )
}
