import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './aboutBanner.css'

export default function Aboutbanner() {
  return (
    <section className="banner-all text-white" style={{backgroundColor: '#f09f09', paddingTop: '5px'}}>
    <Container>
        <Row className="align-items-center" >
            <Col md={6} lg={6} sm={12} >
                <div className="section-title">
                    <h1>About Us</h1>
                </div>
                <div className="breadcrumb-nav">
                    <a href="/" className="text-decoration-none text-white">Home</a>
                    <span href="/" className="text-decoration-none text-white ml-2">About Us</span>
                </div>
            </Col>
            {/*<Col md={6} lg={6} sm={12}>
                <div className="hero-slide-right text-start">
                    <div className="banner-animate">
                        <img src={HeroMainbg} alt="" className="img-fluid" />
                        <img src={staircaseMan} alt="" className="img-fluid a2" />
                        <img src={brushMan} alt="" className="img-fluid a3" />
                        <img src={inject} alt="" className="img-fluid a4" />
                        <img src={injectTool} alt="" className="img-fluid a5" />
                    </div>
                </div>
            </Col>*/}
        </Row>
    </Container>
</section>
  )
}
