import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import shoulder from '../../../../Images/slider/pain-shoulder-joint.jpg';
import kneeRobot from '../../../../Images/slider/robotic.knee.2.jpg';
import surgeons from '../../../../Images/slider/surgeons-passing-scissors-each-other.jpg';
import { Link } from 'react-router-dom';

export default function ReactBootstrapSlider() {
  const slideStyle = {
    position: 'relative',
    height: '50vh',
    overflow: 'hidden',
  };

 

  

  return (
    <div style={slideStyle}>
      <Carousel>
        <Carousel.Item interval={1000}>
          <img src={kneeRobot} style={{ height: '100%', objectFit: 'cover' }} />
          
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <img src={surgeons} style={{ height: '100%', objectFit: 'cover' }} />
          
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <img src={shoulder} style={{ height: '100%', objectFit: 'cover' }} />
          
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
