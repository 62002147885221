import React from 'react';
import { FaFacebook, FaYoutube, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './Icons.css'

export default function IconsSm() {
  return (
    <div className="social-container">
    <a
      href="https://www.facebook.com/profile.php?id=100092630426304"
      className="facebook social"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaFacebook style={{ fontSize: "40px" }} />
    </a>
   
  
    <a
      href="https://twitter.com/Drmanjunat75182"
      className="twitter social"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaTwitter style={{ fontSize: "40px" }} />
    </a>
    <a
      href="https://www.instagram.com/drmanjunathortho/"
      className="instagram social"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaInstagram style={{ fontSize: "40px" }} />
    </a>
    <a
      href="https://www.youtube.com/@drmanjunathOrthopedicSurgeon"
      className="youtube social"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaYoutube style={{ fontSize: "40px" }} />
    </a>
   
  </div>
        
  )
}
