import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from '@emotion/styled';
import robotknee from '../../../../Images/slider/robotic.knee.2.jpg';
import shoulder from '../../../../Images/slider/pain-shoulder-joint.jpg';
import surgeons from '../../../../Images/slider/surgeons-passing-scissors-each-other.jpg';

const SliderContainer = styled.div`
  width: 100%;
  max-width: 1200px; /* Set your desired max width */
  margin: 0 auto;
  overflow: hidden;
`;

const SlideImage = styled.img`
  width: 100%;
  height: auto;
`;

const SlideContent = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
`;

const SlideText = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const SlideButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const Slide = ({ imageSrc, text, buttonText, buttonLink }) => {
  return (
    <SliderContainer>
      <SlideImage src={imageSrc} alt="Slider" />
      <SlideContent>
        <SlideText>{text}</SlideText>
        <SlideButton onClick={() => window.open(buttonLink, '_blank')}>
          {buttonText}
        </SlideButton>
      </SlideContent>
    </SliderContainer>
  );
};

const Slider = () => {
  const slides = [
    {
      imageSrc: robotknee,
      text: 'Slide 1 Text',
      buttonText: 'Button 1',
      buttonLink: 'https://example.com',
    },
    {
      imageSrc: surgeons,
      text: 'Slide 2 Text',
      buttonText: 'Button 2',
      buttonLink: 'https://example.com',
    },
    {
      imageSrc: shoulder,
      text: 'Slide 3 Text',
      buttonText: 'Button 3',
      buttonLink: 'https://example.com',
    },
    // Add more slides as needed
  ];

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      autoPlay
      infiniteLoop
      interval={5000} // Slide change interval in milliseconds (5 seconds here)
    >
      {slides.map((slide, index) => (
        <Slide
          key={index}
          imageSrc={slide.imageSrc}
          text={slide.text}
          buttonText={slide.buttonText}
          buttonLink={slide.buttonLink}
        />
      ))}
    </Carousel>
  );
};

export default Slider;
